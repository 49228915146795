import * as React from "react";
import {useEffect} from "react";
import {PageProps} from "gatsby";
import * as styles from "./styles.module.scss";
import {StaticImage} from "gatsby-plugin-image";
import classNames from "classnames";
import {Breadcrumb} from "../../../components/breadcrumb/breadcrumb";
import Layout from "../../../components/layout/layout";
import Container from "../../../components/container/container";
import Heading from "../../../components/basic-components/heading/heading";
import {ProductBar} from "../../../components/product-bar/product-bar";
import {Level} from "react-accessible-headings";
import scrollTo from "gatsby-plugin-smoothscroll";
import {Helmet} from "react-helmet";
import blogImage from "../../../images/Content-image@3x.jpg";
import {AnchorLink} from "gatsby-plugin-anchor-links";

const CoughPage: React.FC<PageProps> = ({location}: PageProps) => {
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, []);
  return (
      <Layout
          stickyHeader={false}
          seoConfig={{
            title: "Çocuklarda Boğaz Ağrısı Nasıl Geçer?",
            description:
                "Çocuklarda boğaz ağrısı nasıl geçer? Çocuklarda boğaz ağrısı nedenleri ve daha fazlası için sayfamızı ziyaret edin!",
          }}
      >
        <Helmet>
          <script type="application/ld+json">
            {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Öksürük","item": "https://www.bisolnatur.com.tr/oksuruk/"},
                  {"@type": "ListItem","position": 3,"name": "Çocuklarda Boğaz Ağrısı Nasıl Geçer?","item": "${location.href}"}
                ]
            }
          `}
          </script>
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${location.href}"
              },
              "headline": "Öksürük Nedir?",
              "image": "${location.origin + blogImage}",  
              "author": {
                "@type": "Organization",
                "name": "Çocuklarda Boğaz Ağrısı Nasıl Geçer?",
                "url": "${location.origin}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${location.origin}/logo.svg"
                }
              },
              "datePublished": "2021-11-10",
              "dateModified": "2021-11-10"
            }
          `}
          </script>
          <script
              type="application/ld+json">{`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Boğaz Ağrısı Nedir?","acceptedAnswer":{"@type":"Answer","text":"Boğaz ağrısı, bu bölgede çeşitli nedenlerle oluşan kuruluk ve tahriş sonrası meydana gelen bir belirtidir. Okul çağı yaş grubundaki çocuklarda boğaz ağrısı, en sık karşılaşılan şikayetler arasındadır."}},{"@type":"Question","name":"Çocuklarda Boğaz Ağrısı Belirtileri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"Çocuklarda boğaz ağrısı nedenlerinin başında viral enfeksiyonlar gelir. Hatta boğaz ağrısı birçok çocukta soğuk algınlığının ilk belirtisi olarak ortaya çıkar. Bazı çocuklarda ise viral enfeksiyonlar sırasında oluşan boğaz ağrısına, hafif ateş gibi diğer şikayetler de eşlik edebilir."}},{"@type":"Question","name":"Çocuklarda Boğaz Ağrısı Nasıl Geçer?","acceptedAnswer":{"@type":"Answer","text":"Çocuklarda boğaz ağrısı şikayeti, hekimlerinin bilgisi ve önerisi dahilinde yapılabilecek çeşitli uygulamalar ile rahatlatılabilir. Çeşitli cihazlar kullanılarak çocuğun bulunduğu ortamın nem oranının artırılması yapılabilecek uygulamalar arasındadır. Havanın nemlendirilmesi ile çocuğun boğazındaki kuruluğun giderilmesi sağlanabilir ve tahrişe bağlı oluşan boğaz ağrısı şikayetinin hafifletilmesi sağlanabilir. "}}]}`}</script>
        </Helmet>
        <Breadcrumb
            pathname={location.pathname}
            noBg={true}
            className={styles.breadcrumb}
        />
        <Container fullWidth>
          <div className={classNames(styles.header)}>
            <StaticImage
                className={styles.image}
                src="../../../images/cozum/cocuklarda-bogaz-agrisi/Content-image@3x.png"
                alt="Yatakta oturan çocuk görseli"
                width={1680}
                height={450}
            />
            <Container>
              <Heading>Çocuklarda Boğaz Ağrısı Nasıl Geçer?</Heading>
              <ul>
                <li>
                  <AnchorLink
                      className="blue"
                      to="#bogaz-agrisi-nedir"
                      title="Boğaz Ağrısı Nedir?"
                  >
                    Boğaz Ağrısı Nedir?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink
                      className="blue"
                      to="#cocuklarda-bogaz-agrisi-belirtileri-nelerdir"
                      title="Çocuklarda Boğaz Ağrısı Belirtileri Nelerdir? "
                  >
                    Çocuklarda Boğaz Ağrısı Belirtileri Nelerdir?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink
                      className="blue"
                      to="#cocuklarda-bogaz-agrisi-nedenleri-nelerdir"
                      title="Çocuklarda Boğaz Ağrısı Nedenleri Nelerdir? "
                  >
                    Çocuklarda Boğaz Ağrısı Nedenleri Nelerdir?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink
                      className="blue"
                      to="#cocuklarda-bogaz-agrisi-nasil-gecer"
                      title="Çocuklarda Boğaz Ağrısı Nasıl Geçer? "
                  >
                    Çocuklarda Boğaz Ağrısı Nasıl Geçer?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink
                      className="blue"
                      to="#cocuklarda-bogaz-agrisina-ne-iyi-gelir"
                      title="Çocuklarda Boğaz Ağrısına Ne İyi Gelir? "
                  >
                    Çocuklarda Boğaz Ağrısına Ne İyi Gelir?
                  </AnchorLink>
                </li>
              </ul>
            </Container>
          </div>
        </Container>
        <Container>
          <div className={styles.holder}>
            <p id="bogaz-agrisi-nedir" className="extra-bold">
              Birçok ebeveyn çocuğuna baktığında burun tıkanıklığı ya da ateş gibi
              şikayetlerin varlığına dair ipuçları yakalayabilir. Ancak boğaz
              ağrısı, özellikle bu belirtiyi tam olarak ifade edemeyecek bebekler
              ve küçük yaşlardaki çocuklar söz konusu olduğunda dışarıdan kolayca
              anlaşılabilecek bir şikayet değildir. Her ne kadar çocuğun
              şikayetinin anlaşılmaması ebeveynler açısından can sıkıcı bir durum
              olsa da 2 yaştan küçük çocuklarda boğaz ağrısının düşünüldüğü kadar
              ağır bir seyre sahip olmadığı unutulmamalıdır. Boğaz ağrısının bu
              yaş grubunda hafif seyretmesinin nedeni henüz vücutlarının küçük
              tahrişlere bağlı olarak oluşan ağrılara karşı tam bir farkındalık
              geliştirmemesinden kaynaklanıyor olabilir.
            </p>
            <section className={styles.whatHolder}>
              <Level>
                <h2 className="orange underline">Boğaz Ağrısı Nedir? </h2>
                <p>
                  Boğaz ağrısı, bu bölgede çeşitli nedenlerle oluşan kuruluk ve
                  tahriş sonrası meydana gelen bir belirtidir. Okul çağı yaş
                  grubundaki çocuklarda boğaz ağrısı, en sık karşılaşılan
                  şikayetler arasındadır. Boğaz ağrısı, özellikle çocuğun
                  beslenmesi ve konuşması sırasında ağırlaşabilir. Nezle ve grip
                  gibi virüslere bağlı oluşan hastalıklar ile bakteriyel
                  enfeksiyonlar boğaz ağrısının en sık karşılaşılan nedenleri
                  arasında yer alır. Çeşitli nedenlerle oluşabilen boğaz ağrısı,
                  genellikle ciddi bir seyir izlemez ve çoğunlukla kendiliğinden
                  gerileme eğilimindedir. Bu gerileme dönemine kadar geçen süreçte
                  hekimlerin önerisi dahilinde yapabileceğiniz çeşitli uygulamalar
                  sayesinde çocuğunuzda ortaya çıkan şikayetlerin rahatlamasını
                  sağlayabilirsiniz.
                </p>
                <Level>
                  <Heading id="cocuklarda-bogaz-agrisi-belirtileri-nelerdir">
                    Çocuklarda Boğaz Ağrısı Belirtileri Nelerdir?{" "}
                  </Heading>
                </Level>
                <div className={classNames("flexbox", styles.rightCoughHolder)}>
                  <div>
                    <p>
                      Çocuklarda boğaz ağrısı belirtileri, bu şikayetin
                      oluşmasında rol oynayan asıl faktöre bağlı olarak
                      değişkenlik gösterebilir. Çocuklarda boğaz ağrısı
                      nedenlerinin başında viral enfeksiyonlar gelir. Hatta boğaz
                      ağrısı birçok çocukta soğuk algınlığının ilk belirtisi
                      olarak ortaya çıkar. Bazı çocuklarda ise viral enfeksiyonlar
                      sırasında oluşan boğaz ağrısına, hafif ateş gibi diğer
                      şikayetler de eşlik edebilir. Çocuklarda viral enfeksiyon
                      nedeniyle oluşan boğaz ağrısına eşlik edebilen belirtiler şu
                      şekilde sıralanabilir:
                    </p>
                  </div>
                  <div>
                    <StaticImage
                        className={styles.image}
                        src="../../../images/cozum/cocuklarda-bogaz-agrisi/female-paediatrician-examines-child-s-throat-bein-2021-10-21-03-37-31-utc.webp"
                        alt="Muayene görseli"
                        width={384}
                        height={448}
                    />
                  </div>
                  <div className={styles.breakme}/>
                  <div className={styles.listHolder}>
                    <ul>
                      <li className="blue">
                        Boyundaki bezlerde büyüme ve hassasiyet,
                      </li>
                      <li className="blue">Yutkunma güçlüğü,</li>
                      <li className="blue">Baş ağrısı,</li>
                      <li className="blue">
                        Burun tıkanıklığı, burun akıntısı ve hapşırma,
                      </li>
                      <li className="blue">Orta şiddette öksürük.</li>
                    </ul>
                  </div>
                </div>
                <p>
                  Virüslere bağlı oluşan boğaz ağrısına ek olarak{" "}
                  <b>Streptococcus pyogenes</b> adı verilen bir bakteri nedeniyle
                  de çocuklarda boğaz ağrısı meydana gelebilir. Bu enfeksiyon hali
                  geliştiği takdirde genellikle çocukların bademcikleri kırmızı,
                  büyük ve üzeri beyaz lekeli şekilde görünür. Boğazda meydana
                  gelen iltihaplanma, çevre dokuları da etkisi altına alabilir.
                  Streptococcus türü bakterilerle oluşan boğaz iltihabı genellikle
                  okul çağındaki çocukları etkilese de her yaştan çocukta ortaya
                  çıkabileceği için dikkatli olunmalıdır. Bakteriyel kaynaklı
                  boğaz enfeksiyonları çocuklarda yetişkinlere göre daha sık
                  görülür. Bu rahatsızlık açısından riskli grup özellikle 5-15 yaş
                  arasındaki çocuklardır. Streptococcus bakterisi kaynaklı boğaz
                  ağrısı şikayetine eşlik edebilen belirtiler, yaşa göre
                  değişkenlik göstermekle birlikte genel olarak şu şekilde
                  özetlenebilir:
                </p>
                <ul>
                  <li className="blue">Ateş,</li>
                  <li className="blue">
                    Boyun bölgesindeki lenf nodlarında büyüme ve hassasiyet,
                  </li>
                  <li className="blue">Huzursuzluk,</li>
                  <li className="blue">İştah azalması,</li>
                  <li className="blue">
                    Şiş bademciklerin üzerinde beyaz iltihapların gelişmesi,
                  </li>
                  <li className="blue">
                    Ağız içerisinde özellikle üst damakta belirgin kırmızı
                    lekelenmeler oluşması,
                  </li>
                  <li className="blue">Baş ağrısı,</li>
                  <li className="blue">Karın ağrısı, bulantı, kusma.</li>
                </ul>
                <p>
                  3 yaşından küçük çocuklarda bu bakteri türü ile boğaz ağrısı
                  oluşması, sık karşılaşılan bir durum değildir. Meydana gelmesi
                  halinde ise genellikle hafif bir seyir izleme eğilimindedir.
                  Gerekli durumlarda hekimler, bakteriyel boğaz enfeksiyonlarını
                  takiben oluşabilen romatizmal ateş veya böbrek iltihaplanması
                  gibi durumlara önlem almak amacıyla çeşitli antibiyotik
                  ilaçların reçetelendirilmesine başvurabilir.
                </p>
                <StaticImage
                    className={styles.image}
                    src="../../../images/cozum/cocuklarda-bogaz-agrisi/toddler-child-playing-in-a-snow-in-winter-2021-08-28-07-46-09-utc.webp"
                    alt="Hapşuran çocuk görseli"
                    width={792}
                    height={430}
                />
              </Level>
            </section>
            <section
                id="cocuklarda-bogaz-agrisi-nedenleri-nelerdir"
                className={styles.typesHolder}
            >
              <Level>
                <Level>
                  <Heading>Çocuklarda Boğaz Ağrısı Nedenleri Nelerdir? </Heading>
                </Level>
                <p>
                  Çocuklarda boğaz ağrısı genellikle altta yatan bir durumun
                  sonucu olarak meydana gelir. Ancak bazı çocuklarda çeşitli
                  çevresel faktörlere bağlı olarak da boğaz ağrısı şikayeti
                  oluşabileceği unutulmamalıdır. Çocuklarda boğaz ağrısı nedenleri
                  genel olarak şu şekilde özetlenebilir:
                </p>
                <ul>
                  <li className="blue">
                    Soğuk algınlığı, çocuklarda boğaz ağrısı nedenlerinin başında
                    gelir. Virüslere bağlı oluşan soğuk algınlığı gibi durumlarda
                    antibiyotik ilaçların kullanımının gereksiz olduğu
                    unutulmamalıdır. Soğuk algınlığı nedeniyle oluşan boğaz ağrısı
                    genellikle 10-14 gün arasında değişen bir zaman diliminde
                    kendiliğinden gerileme gösterir.
                  </li>
                  <li className="blue">
                    Grip, soğuk algınlığı gibi bir diğer virüslere bağlı oluşan
                    solunum yolu rahatsızlığıdır ve boğaz ağrısı şikayetine neden
                    olabilir. Soğuk algınlığından farklı olarak gripte oluşan
                    şikayetler daha şiddetli olabilir. Ateş, üşüme, titreme, kuru
                    öksürük, burun tıkanıklığı halsizlik ve kusma, çocuklarda grip
                    gelişimi ile birlikte boğaz ağrısına eşlik edebilecek
                    belirtiler arasında yer alır.
                  </li>
                  <li className="blue">
                    El, ayak, ağız hastalığı, küçük çocuklarda yaygın olarak
                    görülen bir diğer viral hastalıktır. Bu rahatsızlık özellikle
                    yaz ve sonbahar döneminde daha sık olarak tespit edilir. El,
                    ayak, ağız hastalığının karakteristik bulguları arasında boğaz
                    ağrısı yer alır. Bu rahatsızlığın seyri sırasında boğaz
                    bölgesinde oluşan küçük vezikül ve yaralar çocuğun yutkunma
                    sırasında boğaz ağrısı hissetmesine neden olur. Boğaz ağrısına
                    ek olarak el, ayak, ağız hastalığının seyri sırasında ateş ve
                    hastalığa ismini veren bölgelerde küçük kırmızı lezyonlar
                    ortaya çıkar. El, ayak, ağız hastalığı oldukça bulaşıcı bir
                    rahatsızlıktır. Hastalığın aktif olarak devam dönemlerde
                    (yaklaşık 7-10 gün) oluşan cilt lezyonları tamamen iyileşene
                    kadar çocuğun diğer çocuklardan izole edilmesinde fayda
                    vardır.
                  </li>
                  <li className="blue">
                    El, ayak, ağız hastalığı etkeni olan virüs aynı zamanda
                    herpanjina adı verilen ağız ve boğaz bölgesinde ağrılı yara
                    oluşumuna da neden olabilir. Oluşan lezyonlar ağrı, ateş ve
                    yutkunma zorluğu gibi şikayetler ile seyreder. Lezyonların
                    merkezi beyaz gri renkte olup çevresi ise kırmızı renklidir.
                    Herpanjina en sık olarak 3-10 yaş arasındaki çocukları
                    etkilese de daha küçük çocuklar ve bebekler de bu rahatsızlığa
                    yakalanabilir.
                  </li>
                  <li className="blue">
                    Virüsler dışında bakterilerin boğaz bölgesinde meydana
                    getirdiği enfeksiyonlar da çocuklarda sık karşılaşılan boğaz
                    ağrısı nedenleri arasında yer alır. Bu bakteriyel
                    enfeksiyonlardan korunmak adına çocukların doğru el yıkama ve
                    el hijyenini sağlama uygulamalarını öğrenmesi oldukça
                    önemlidir.
                  </li>
                  <li className="blue">
                    Alerjiler, çocuklarda boğaz ağrısına neden olabilir. Alerjik
                    durumların çocuklarda ortaya çıkışı genellikle okul öncesi
                    çağda gerçekleşir. Hayvan tüyleri, küf, çimen ve polen gibi
                    maddeler, alerjik durumların en sık karşılaşılan
                    tetikleyicilerine örnek teşkil ederler. Alerjen madde ile
                    temas sonrasında çocuğun vücudunda bağışıklık sistemi anormal
                    bir yanıt oluşturur. Bu anormal yanıtı takiben burun akıntısı,
                    geniz akıntısı ve boğaz ağrısı gibi şikayetler ortaya
                    çıkabilir. Gözlerde kaşınma ve hapşırma sık olarak görülen
                    diğer alerji belirtileri arasında yer alır.
                  </li>
                  <li className="blue">
                    Yetişkinlerde olduğu gibi çocuklar da hava kirliliğine ve
                    sigara dumanı gibi çeşitli tahriş edici kimyasallara karşı
                    oldukça hassastır. Bu maddelerin solunması halinde çocukların
                    boğazında tahriş ve ağrı gibi şikayetler oluşabilir. Sadece bu
                    durum bile çocukların yanında asla tütün ürünleri
                    kullanılmaması gerektiğinin önemli bir nedenidir. Çocukların
                    içerisinde bulunduğu ortamın havası mümkün olduğunca temiz
                    olmalıdır.
                  </li>
                </ul>
                <StaticImage
                    className={styles.image}
                    src="../../../images/cozum/cocuklarda-bogaz-agrisi/naughty-caucasian-infant-kid-is-fussy-and-refuses-2021-08-31-07-03-46-utc.webp"
                    alt="Hapşuran çocuk görseli"
                    width={792}
                    height={430}
                />
                <p>
                  Okul çağı çocuklarına ek olarak henüz yeni yürüme döneminde olan
                  çocuklarda ve küçük bebeklerde de boğaz ağrısı şikayeti ortaya
                  çıkabilir. Henüz kendisini tam olarak ifade edemediği dönemde
                  bulunan çocuklarda, çeşitli belirtilerin varlığı incelenerek
                  boğaz ağrısı şikayetine dair fikir elde edilebilir. Küçük
                  çocukların boğaz ağrısı çekiyor olabileceğini gösteren
                  belirtiler genel olarak şöyledir:
                </p>
                <ul>
                  <li className="blue">
                    Sevdiği yiyecek ve içecekleri tüketmeyi reddetmesi,
                  </li>
                  <li className="blue">
                    Yutkunma ile birlikte ağlamaya başlaması veya yüzünü ağrı
                    hissediyor gibi buruşturması,
                  </li>
                  <li className="blue">
                    Boyun bölgesinde her iki yanda yer alan bezlerde şişkinlik
                    meydana gelmesi,
                  </li>
                  <li className="blue">
                    Boğazın arka kısmının kızarık ve ödemli görünmesi,
                  </li>
                  <li className="blue">
                    Bademcikler üzerinde beyaz lekeler görülmesi,
                  </li>
                  <li className="blue">Ağız kokusu.</li>
                </ul>
                <p>
                  Bebeklerde ve küçük çocuklarda oluşan şikayetlerin hepsi
                  pediatri hekimlerine bildirilmeli ve takip edilmelidir. Boğaz
                  ağrısı şikayetine ek olarak aşağıda yer alan belirtilerin de
                  oluşması halinde ise sağlık kuruluşlarına başvurularak uzman
                  hekimlere muayene olunması gerekir:
                </p>
                <ul>
                  <li className="blue">38 derecenin üzerine çıkan ateş,</li>
                  <li className="blue">Boğaz ağrısının kötüleşmesi,</li>
                  <li className="blue">Yeterli sıvı alımının sağlanamaması,</li>
                  <li className="blue">Kulak ağrısı,</li>
                  <li className="blue">
                    Boyun bölgesindeki bezlerde meydana gelen şişliklerin
                    kötüleşmesi,
                  </li>
                  <li className="blue">Nefes alıp vermede zorlanma,</li>
                  <li className="blue">Ciddi baş ağrısı gelişmesi,</li>
                  <li className="blue">
                    Boğaz ağrısını takiben birkaç hafta içerisinde eklemlerde
                    kızarıklık, hassasiyet ve ağrı gibi şikayetler gelişmesi,
                  </li>
                  <li className="blue">Cilt döküntülerinin oluşması,</li>
                  <li className="blue">
                    Boğaz ağrısından 3-4 hafta sonrasında idrar renginde koyulaşma
                    meydana gelmesi.
                  </li>
                </ul>
              </Level>
            </section>
            <section
                id="cocuklarda-bogaz-agrisi-nasil-gecer"
                className={styles.whyHolder}
            >
              <Level>
                <Level>
                  <Heading>Çocuklarda Boğaz Ağrısı Nasıl Geçer? </Heading>
                </Level>
                <p>
                  Çocuklarda boğaz ağrısı şikayeti, hekimlerinin bilgisi ve
                  önerisi dahilinde yapılabilecek çeşitli uygulamalar ile
                  rahatlatılabilir. Çeşitli cihazlar kullanılarak çocuğun
                  bulunduğu ortamın nem oranının artırılması yapılabilecek
                  uygulamalar arasındadır. Havanın nemlendirilmesi ile çocuğun
                  boğazındaki kuruluğun giderilmesi sağlanabilir ve tahrişe bağlı
                  oluşan boğaz ağrısı şikayetinin hafifletilmesi sağlanabilir.
                </p>
                <StaticImage
                    className={styles.image}
                    src="../../../images/cozum/cocuklarda-bogaz-agrisi/two-kids-eating-soup-and-vegetables-for-lunch-at-h-2021-09-02-15-23-06-utc@3x.png"
                    alt="Hapşuran çocuk görseli"
                    width={792}
                    height={430}
                />
                <p>
                  Ilık çorba ve çeşitli içeceklerin hazırlanması çocuklarda boğaz
                  ağrısının dindirilmesi adına atılabilecek bir diğer adımdır.
                  Aynı zamanda çocuğun yeterince dinlenmesine ve günlük sıvı
                  tüketiminin artırılmasına da dikkat edilmelidir. Büyük
                  çocuklarda dondurma gibi soğuk gıdalar boğaz ağrısı şikayetinin
                  dindirilmesine katkı sağlayabilir. Boğaz pastili ve diğer sert
                  şekerler özellikle 4 yaşından küçük çocuklarda boğulmalara neden
                  olabileceği için kullanılmamalıdır.
                </p>
              </Level>
            </section>
            <section
                id="cocuklarda-bogaz-agrisina-ne-iyi-gelir"
                className={styles.kidsHolder}
            >
              <Level>
                <Level>
                  <Heading>
                    Bisolduo Şurup ve Bisolduo Action Sprey: Soğuk Algınlığına
                    Bağlı Boğaz Ağrısını Yatıştırır
                  </Heading>
                  <p>
                    Bisolduo Şurup ve Bisolduo Action Sprey 2 yaşından büyük
                    çocuklarda öksürüğü rahatlatmaya ve boğaz ağrısını
                    yatıştırmaya yardımcı olur. Bisolduo Şurup, boğaz tahrişine
                    neden olan dış faktörlere karşı mukozayı kaplar, kuru öksürüğü
                    rahatlatıp boğaz ağrısını yatıştırır. Bisolduo Action Sprey
                    içeriğinde bulunan Ectoin sayesinde anında etki göstererek
                    öksürüğü rahatlatır, boğaz ağrısını azaltır.
                  </p>
                </Level>
              </Level>
              <div className={classNames("flexbox", styles.productHolder)}>
                <StaticImage
                    className={styles.image}
                    src="../../../images/product-bar-bisolduo-surup.png"
                    alt="Bisolnatur ürün görseli"
                    width={282}
                    height={282}
                />
                <div className={styles.content}>
                  <p>
                    <b>Bisolduo Şurup</b>, içerisinde saf ve doğal bala ek olarak
                    hatmi kökü bitkisinin kuru ekstresini içerir. Bal, yatıştırıcı
                    etkileri sayesinde kurumuş ve ağrılı boğazın doğal şekilde
                    sakinleştirilmesine yardımcı olur. Hatmi kökü ise yoğun kıvamı
                    ile boğazın kaplanmasında faydalıdır. Gluten ve alkol dışında
                    laktoz, yapay aroma ve koruyucu içermeyen Bisolduo Şurup aynı
                    zamanda hoş bir tada sahiptir. Yetişkinler ve 2 yaş üzerindeki
                    çocuklar için kullanımı uygun olan Bisolduo Şurup, 2-6 yaş
                    arasındaki çocuklarda günde en fazla 3 defa olacak şekilde
                    yarım tatlı kaşığı (2,5 ml) olarak kullanılabilir. 6-12 yaş
                    arasındaki çocuklarda günde en fazla 3 defa olacak şekilde 1
                    tatlı kaşığı (5 ml) olarak tüketilebilir; yetişkinler ve
                    adolesanlarda günde en fazla 6 defa olacak şekilde 1 tatlı
                    kaşığı (5 ml) olarak tüketilebilir.
                  </p>
                </div>
              </div>
              <div className={classNames("flexbox", styles.productHolder)}>
                <StaticImage
                    className={styles.image}
                    src="../../../images/product-bar-bisolduo-sprey.png"
                    alt="Bisolnatur ürün görseli"
                    width={282}
                    height={282}
                />
                <div className={styles.content}>
                  <p>
                    <b>Bisolduo Action Sprey</b>, soğuk algınlığı, öksürük ve
                    boğaz tahrişinin ilk belirtilerinin tedavisi için
                    kullanılabilir. Bisolduo Action Sprey semptomların
                    kötüleşmesini önler, öksürüğü ve tahriş olmuş boğazı
                    rahatlatır; ayrıca boğaz ağrısı ve ses kısıklığı gibi boğaz
                    rahatsızlığı semptomlarını rahatlatır. Boğaz bölgesindeki
                    kurumuş ve tahrişe uğramış dokunun nemlendirilmesini sağlayan
                    Bisolduo Action Sprey, aynı zamanda bu bölgedeki mukozanın
                    bariyer işlevini koruyarak yenilenmesine yardımcı olur. Bal ve
                    hatmi kökü ekstresine ek olarak doğal Ectoin maddesini de
                    içeren Bisolduo Action Sprey, soğuk algınlığı sonucu oluşan
                    şikayetlerin bölgesel olarak hafifletilmesini destekler. 2
                    yaşından büyük çocuklar ve yetişkinler için uygun olan
                    Bisolduo Action Sprey, 2-5 yaş arasındaki çocuklarda günde 3
                    kez, 1 defa püskürtülerek kullanılabilir. 6-11 yaş arasındaki
                    çocuklar için günde 3 kez, 2 defa püskürtülerek kullanılan
                    Bisolduo Action Sprey, bu yaş aralığının üzerindeki çocuklarda
                    ve yetişkin bireylerde günde birkaç kez 4 defa püskürtülerek
                    kullanılabilir.
                  </p>
                </div>
              </div>
            </section>
            <section className={styles.kaynakca}>
              <Level>
                <Level>
                  <Heading id="kaynakca">Kaynakça</Heading>
                </Level>
              </Level>
              <p>
                1-
                https://www.whattoexpect.com/childrens-health-and-safety/sore-throats-in-children.aspx
              </p>
              <p>
                2- https://www.healthline.com/health/baby/baby-sore-throat
              </p>
              <p>
                3-
                https://pediatricassociatesnc.com/Your-Child-Has-a-Sore-Throat-What-s-the-Cause
              </p>
              <p>
                4- https://www.medicalnewstoday.com/articles/311449
              </p>
              <p>
                5-
                https://www.bisolnatur.com.tr/urunler/bisolduo-surup/nasil-etki-eder/
              </p>
              <p>
                6- https://www.bisolnatur.com.tr/urunler/bisolduo-sprey/
              </p>
              <p>
                7-
                https://www.healthline.com/health-news/why-your-kid-keeps-getting-strep-throat
              </p>
            </section>
          </div>
          <div className="date">
            <p>Yayınlama Tarihi: 2021-11-10</p>
            <p>Son güncellenme Tarihi: 2021-11-10</p>
          </div>
        </Container>
        <ProductBar/>
      </Layout>
  );
};
export default CoughPage;
