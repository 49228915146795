// extracted by mini-css-extract-plugin
export var breadcrumb = "styles-module--breadcrumb--8I7-H";
export var breakme = "styles-module--breakme--JWw7i";
export var content = "styles-module--content--UWGvu";
export var header = "styles-module--header--ceneK";
export var holder = "styles-module--holder--po61g";
export var howHolder = "styles-module--howHolder--N9enF";
export var image = "styles-module--image--DgJo7";
export var kaynakca = "styles-module--kaynakca--lm-Wu";
export var kidsHolder = "styles-module--kidsHolder--fHfJe";
export var listHolder = "styles-module--listHolder--pslDP";
export var productHolder = "styles-module--productHolder--BHGQ3";
export var rightCoughHolder = "styles-module--rightCoughHolder--QZH-G";
export var typesHolder = "styles-module--typesHolder--k9xDO";
export var whatHolder = "styles-module--whatHolder--2pnsT";
export var whyHolder = "styles-module--whyHolder--br43e";